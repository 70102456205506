import React, { useEffect, useMemo, useState } from 'react';
import { FullPageLayout } from '../../layouts';
import { LoadingThrobber } from '../../components';
import { Button, Dropdown, Tab, Tabs } from '@orderly/morrisons-component-library';
import { QUERY_KEYS } from '../../constants';
import styles from './Invoices.module.scss';
import {
  downloadFinancialBlob,
  FinanciaInvoiceTypeNumber,
  getFinancialInvoices,
  IFinancialDocumentsResponse,
} from '../../api/FinancialDocuments/financialDocumentsApi';
import Table from '../../components/Table/Table';
import { IFinancialInvoice, IRetailerStore, IRetailerStores, IStoreState } from '../../types';
import { Pagination } from 'react-bootstrap';
import { formatCurrencyLocale } from '../../helpers/Format/formatCurrency';
import { formatShortDateString } from '../../helpers/Format';
import { GenerateDownload } from '../../helpers/DownloadHelper';
import { connect } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

const itemsPerPage = 25;
const tabs: Record<string, string> = {
  ALL: 'All',
  INVOICE: 'Invoices',
  REBATE: 'Rebates',
  CREDIT_NOTE: 'Credit Notes',
};

interface IProps {
  userStores: IRetailerStores;
}

const getInvoiceNumberByTab = (tab: string) => {
  switch (tab) {
    case 'INVOICE':
      return 0;
    case 'REBATE':
      return 1;
    case 'CREDIT_NOTE':
      return 2;
    default:
      return -1;
  }
};

function Invoices({ userStores }: Readonly<IProps>): JSX.Element {
  const [activeTab, setActiveTab] = useState<string>('ALL');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedStore, setSelectedStore] = useState<number>(null);

  const { data: invoices, isLoading } = useQuery<IFinancialDocumentsResponse<IFinancialInvoice>, Error>(
    [QUERY_KEYS.FINANCIAL_INVOICES, selectedStore, currentPage, activeTab],
    () => getFinancialInvoices({
      externalStoreId: selectedStore,
      invoiceType: getInvoiceNumberByTab(activeTab),
      pageSize: itemsPerPage,
      startIndex: (currentPage - 1) * itemsPerPage,
    }),
    {
      enabled: selectedStore !== null,
    },
  );

  useEffect(() => {
    if (invoices && invoices.data) {
      if (invoices.data.length > 0) {
        setTotalPages(Math.ceil(invoices.totalDocumentsCount / itemsPerPage));
      }
    }

  }, [invoices, currentPage]);

  useEffect(
    () => {
      if (userStores && userStores.stores.length > 0) {
        setSelectedStore(userStores.stores[0].externalId);
      }
    },
    [userStores],
  );

  const onInvoiceDownload = async (documentId: number, type: number, externalStoreId: number) => {
    const result = await downloadFinancialBlob(documentId, type, externalStoreId);

    if (result) {
      GenerateDownload(result.invoice.data, result.invoice.fileName);
    }
  };

  const invoicesTable = useMemo(
    () => {
      const data = (invoices && invoices.data) ? invoices.data : [];
      return (
        <Table className={styles.invoices}>
          <thead className={styles.tableHeader}>
            <tr>
              <th>Date</th>
              {
                activeTab === 'ALL' && <th>Invoice Type</th>
              }
              <th>Invoice Number</th>
              <th>Total Items</th>
              <th>Goods Ex VAT - Other</th>
              <th>VAT - Other</th>
              <th>20% Goods Ex VAT</th>
              <th>20% VAT</th>
              <th>0% Goods</th>
              <th>Total Goods</th>
              <th>Total VAT</th>
              <th>Invoice Total</th>
            </tr>
          </thead>
          <tbody>
            {
              !data.length &&
              <tr>
                <td colSpan={activeTab === 'ALL' ? 12 : 11}>
                  No records found.
                </td>
              </tr>
            }
            {
              data.map((invoice: IFinancialInvoice) => {
                return (
                  <tr key={invoice.id}>
                    <td>{formatShortDateString(invoice.invoiceDate.toString())}</td>
                    {
                      activeTab === 'ALL' && <td>{FinanciaInvoiceTypeNumber[invoice.invoiceType]}</td>}
                    <td
                      className={styles.invoiceNo}
                      onClick={() => onInvoiceDownload(invoice.id, invoice.invoiceType, selectedStore)}
                    >
                      <a href={invoice.fileName} rel="noreferrer" target="_blank">{invoice.invoiceNo}</a>
                    </td>
                    <td>
                      {
                        invoice.invoiceType === FinanciaInvoiceTypeNumber.REBATE ? 'N/A' : invoice.totalItems
                      }
                    </td>
                    <td>{formatCurrencyLocale(invoice.goodsExVatOther)}</td>
                    <td>{formatCurrencyLocale(invoice.vatOther)}</td>
                    <td >{formatCurrencyLocale(invoice.goodsExVat20)}</td>
                    <td>{formatCurrencyLocale(invoice.vat20)}</td>
                    <td>{formatCurrencyLocale(invoice.goods0)}</td>
                    <td>{formatCurrencyLocale(invoice.totalGoods)}</td>
                    <td>{formatCurrencyLocale(invoice.totalVAT)}</td>
                    <td>{formatCurrencyLocale(invoice.invoiceTotal)}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      );
    },
    [invoices, activeTab, currentPage, selectedStore],
  );

  const changeTab = (tab: string) => {
    setCurrentPage(1);
    setActiveTab(tab);
  };

  const selectStore = (externalStoreId: number) => {
    setCurrentPage(1);
    setSelectedStore(externalStoreId);
  };

  return (
    <FullPageLayout
      heading="Invoices"
      breadcrumbs={[
        {
          key: 0,
          text: 'Invoices',
          url: '/invoices',
        },
      ]}
      homeRoute="/home"
      headerAdditionalContent={
        (userStores != null && userStores.stores != null && userStores.stores.length > 1) &&
        <Dropdown
          className={styles.storeSelect}
          label=""
          name="store-sort"
          onChange={value => selectStore(value.value as number)}
          selectedValue={selectedStore}
          options={userStores.stores.map((store: IRetailerStore) => {
            return {
              value: store.externalId,
              label: `${store.name} [${store.externalId}]`,
            };
          })}
        />
      }
    >
      <div className={styles.container}>
        {isLoading && <LoadingThrobber />}
        <Tabs onChange={changeTab} activeTab={activeTab.toString()}>
          {
            Object.keys(tabs).map(x =>
              <Tab key={x} id={x} title={tabs[x]}>
                <div className={styles.tab}>
                  {invoicesTable}
                  {
                    totalPages > 1 &&
                    <Pagination className={styles.tablePagination}>
                      <Button
                        className="tertiary"
                        type="submit"
                        text="Previous"
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                      />
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>
                      <Button
                        className="tertiary"
                        type="submit"
                        text="Next"
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  }
                </div>
              </Tab>,
            )
          }
        </Tabs>
      </div>
    </FullPageLayout>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    userStores: state.userStores.data,
  };
};

export default connect(mapStateToProps)(Invoices);
