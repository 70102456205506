import { AxiosResponse } from 'axios';
import {
  submitOrderDispatchApi, getOrderDispatchApi, importOrderDispatchApi, exportOrderDispatchApi,
  IExportLogisticsConfirmationFormParams,
  saveOrderDispatchApi,
} from '../../api/Orders/orderDispatchApi';
import { IOrderDispatchResponse } from '../../api/LogisticsOrderDispatch/IOrderDispatchResponse';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { IOrderDispatch, IStoreState } from '../../types';
import { store } from '../../store';
import { IExportLogisticsConfirmationFormResponse }
  from '../../api/LogisticsOrderDispatch/IExportLogisticsConfirmationFormResponse';
import {
  IImportLogisticsConfirmationFormResponse,
} from '../../api/LogisticsOrderDispatch/IImportLogisticsConfirmationFormResponse';
import {
  IImportLogisticsConfirmationFormParams,
} from '../../api/LogisticsOrderDispatch/IImportLogisticsConfirmationFormParams';

export const GET_ORDER_DISPATCH_REQUEST = 'GET_ORDER_DISPATCH_REQUEST';
export const GET_ORDER_DISPATCH_RESPONSE = 'GET_ORDER_DISPATCH_RESPONSE';
export const GET_ORDER_DISPATCH_ERROR = 'GET_ORDER_DISPATCH_ERROR';

export const SUBMIT_ORDER_DISPATCH_REQUEST = 'SUBMIT_ORDER_DISPATCH_REQUEST';
export const SUBMIT_ORDER_DISPATCH_RESPONSE = 'SUBMIT_ORDER_DISPATCH_RESPONSE';
export const SUBMIT_ORDER_DISPATCH_ERROR = 'SUBMIT_ORDER_DISPATCH_ERROR';

export const SAVE_ORDER_DISPATCH_REQUEST = 'SAVE_ORDER_DISPATCH_REQUEST';
export const SAVE_ORDER_DISPATCH_RESPONSE = 'SAVE_ORDER_DISPATCH_RESPONSE';
export const SAVE_ORDER_DISPATCH_ERROR = 'SAVE_ORDER_DISPATCH_ERROR';

export const IMPORT_ORDER_DISPATCH_REQUEST = 'IMPORT_ORDER_DISPATCH_REQUEST';
export const IMPORT_ORDER_DISPATCH_RESPONSE = 'IMPORT_ORDER_DISPATCH_RESPONSE';
export const IMPORT_ORDER_DISPATCH_ERROR = 'IMPORT_ORDER_DISPATCH_ERROR';

export const EXPORT_ORDER_DISPATCH_REQUEST = 'EXPORT_ORDER_DISPATCH_REQUEST';
export const EXPORT_ORDER_DISPATCH_RESPONSE = 'EXPORT_ORDER_DISPATCH_RESPONSE';
export const EXPORT_ORDER_DISPATCH_ERROR = 'EXPORT_ORDER_DISPATCH_ERROR';

export const getOrderDispatchRequest = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ORDER_DISPATCH_REQUEST,
    });

    return getOrderDispatchApi()
      .then((response: AxiosResponse<IOrderDispatchResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_ORDER_DISPATCH_RESPONSE,
            response: response.data.getLogisticsConfirmationOrders,
          });
        } else {
          getOrderDispatchError(dispatch, response.data as unknown as string);
        }
      })
      .catch((err: any) => {
        console.log(err);
        getOrderDispatchError(dispatch);
      });
  };
};

const getOrderDispatchError = (dispatch: any, message: string = null) => dispatch({
  type: GET_ORDER_DISPATCH_ERROR,
  response: message,
});

export const submitOrderDispatchRequest = (order: IOrderDispatch): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    const state: IStoreState = store.getState();

    if (!state.orderDispatch.loading) {
      dispatch({
        type: SUBMIT_ORDER_DISPATCH_REQUEST,
      });

      try {
        const response = await submitOrderDispatchApi({ order });

        if (response.status === 200) {
          toastr.success('Order Submit', 'Order has been submitted successfully.');
          dispatch({
            type: SUBMIT_ORDER_DISPATCH_RESPONSE,
          });

          dispatch(getOrderDispatchRequest());

        } else {
          onSubmitOrderDispatchError(dispatch);
        }
      } catch (error) {
        console.log(error);
        onSubmitOrderDispatchError(dispatch);
      }
    }
  };
};

const onSubmitOrderDispatchError = (dispatch: Dispatch) => dispatch({
  type: SUBMIT_ORDER_DISPATCH_ERROR,
});

export const saveOrderDispatchRequest = (order: IOrderDispatch): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    const state: IStoreState = store.getState();

    if (!state.orderDispatch.loading) {
      dispatch({
        type: SAVE_ORDER_DISPATCH_REQUEST,
      });

      try {
        const response = await saveOrderDispatchApi({ order });

        if (response.status === 200) {
          toastr.success('Order Save', 'Order has been saved successfully.');
          dispatch({
            type: SAVE_ORDER_DISPATCH_RESPONSE,
          });

          dispatch(getOrderDispatchRequest());

        } else {
          onSaveOrderDispatchError(dispatch);
        }
      } catch (error) {
        console.log(error);
        onSaveOrderDispatchError(dispatch);
      }
    }
  };
};

const onSaveOrderDispatchError = (dispatch: Dispatch) => dispatch({
  type: SAVE_ORDER_DISPATCH_ERROR,
});

export const importOrderDispatchRequest = (
  parameters: IImportLogisticsConfirmationFormParams,
  onSuccess: (data: string[]) => void): any => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: IMPORT_ORDER_DISPATCH_REQUEST,
    });

    return importOrderDispatchApi(parameters)
      .then((response: AxiosResponse<IImportLogisticsConfirmationFormResponse>) => {
        if (response.status === 200) {
          onSuccess(response.data.importLogisticsConfirmationForm.results);
          if (response.data.importLogisticsConfirmationForm.success) {
            toastr.success('Import Success', 'The logistics confirmation form has been imported successfully.');
            dispatch({
              type: IMPORT_ORDER_DISPATCH_RESPONSE,
            });

            dispatch(getOrderDispatchRequest());

          } else {
            toastr.error('Import Error',
              'An error has occurred whilst importing the logistics confirmation form. Please try again.');
          }
        } else {
          onImportOrderDispatchError(dispatch);
        }
      }).catch((err: any) => {
        console.log(err);
        onImportOrderDispatchError(dispatch);
      });
  };
};

const onImportOrderDispatchError = (dispatch: any) => {
  dispatch({
    type: IMPORT_ORDER_DISPATCH_ERROR,
  });
  toastr.error('Import Error',
    'An error has occurred whilst importing the logistics confirmation form. Please try again.');
};

export const exportOrderDispatchRequest = (
  parameters: IExportLogisticsConfirmationFormParams,
  onSuccess: (data: any) => void,
): any => {
  return (dispatch: any) => {
    dispatch({
      type: EXPORT_ORDER_DISPATCH_REQUEST,
    });

    return exportOrderDispatchApi(parameters)
      .then((response: AxiosResponse<IExportLogisticsConfirmationFormResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: EXPORT_ORDER_DISPATCH_RESPONSE,
          });
          toastr.success('Order Exported', 'The order form has been exported successfully.');
          onSuccess(response.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onExportOrderDispatchError(dispatch);
      });
  };
};

const onExportOrderDispatchError = (dispatch: any) => {
  dispatch({
    type: EXPORT_ORDER_DISPATCH_ERROR,
  });
  toastr.error('Error', 'An error has occurred whilst exporting the supply demand order form. Please try again.');
};
