import { PageMode } from './../../ManageUsers/enums/ManageUsers.enum';
import IValidationResult from '../../../../helpers/validator/IValidationResult';
import IPendingField from '../../../../helpers/validator/IPendingField';
import { ICreateUserHelper } from './interfaces/CreateUserHelper.interface';
import { ICreateUserState, ICreatedUser, ICreateUserPayload, ICreatUserComponent } from '../interfaces/CreateUser.interface';
import Validator from '../../../../helpers/validator/Validator';
import { DropdownOption } from '@orderly/morrisons-component-library';
import { IRegion, IRetailer, IRetailerStore } from '../../../../types';
import { ICreateUserFormForm, ICreateUserFormBreadCrumb, ICreaterUserFormConfig }
  from '../form/interfaces/CreateUserForm.interface';
import { ValidatorOptionRules } from '../../../../helpers/validator/ValidatorOptionRules';
import { Claims } from '../../../../helpers/Users/Claims.enum';
import { getNumericalKeys, getNormalizedEnumString } from '../../../../helpers/EnumHelper';

export class CreateUserHelper implements ICreateUserHelper {
  private _validator: Validator;
  private _createUserComponentInstance: ICreatUserComponent;
  private _options: DropdownOption[];
  private _roleOptions: DropdownOption[];
  private _headingText: string;
  private _createButtonText: string;

  constructor(createUserComponent: ICreatUserComponent) {
    this._createUserComponentInstance = createUserComponent;
    this.setCreateOrUpdateText(this._createUserComponentInstance.pageMode === PageMode.Admin);
  }

  public getPayload(userToEdit: ICreatedUser, state: ICreateUserState): string {
    const { firstname, lastname, emailAddress, retailerId, role, regionId, userStores } = state;
    const payload: ICreateUserPayload = {
      regionId: Number(regionId.value),
      role: Number(role.value),
      firstname: firstname.value.toString(),
      lastname: lastname.value.toString(),
      emailAddress: emailAddress.value.toString(),
      retailerId: (retailerId && retailerId.value != null) ? parseInt(retailerId.value.toString(), 10) : -1,
      stores: userStores.map((store) => { return store.id; }),
    };

    if (!!userToEdit) {
      payload.userId = userToEdit.userId;
    }

    return JSON.stringify(payload);
  }

  public initiateState(user: ICreatedUser, pageMode: string): ICreateUserState {
    const isEdit: boolean = (user && !!user.userId);

    return {
      firstname: {
        value: isEdit ? user.firstname : '',
        error: '',
      },
      lastname: {
        value: isEdit ? user.lastname : '',
        error: '',
      },
      emailAddress: {
        value: isEdit ? user.emailAddress : '',
        error: '',
      },
      retailerId: {
        value: isEdit ? user.retailerId.toString() : null,
        error: '',
      },
      regionId: {
        value: isEdit ? user.regionId : -1,
        error: '',
      },
      role: {
        value: pageMode === PageMode.Admin ? isEdit ? user.claims[0] : Claims['Admin'] : Claims.User,
        error: '',
      },
      regions: [],
      isLoading: false,
      isDisabledOnSubmit: false,
      userStores: [],
    };
  }

  public initiateValidation(): void {
    this._validator = new Validator({
      firstname: {
        [Validator.Rules.required]: {
          [ValidatorOptionRules.MAXLENGTH]: 50,
        },
        [Validator.Rules.alphabet]: true,
      },
      lastname: {
        [Validator.Rules.required]: {
          [ValidatorOptionRules.MAXLENGTH]: 100,
        },
        [Validator.Rules.alphabet]: true,
      },
      emailAddress: {
        [Validator.Rules.required]: {
          [ValidatorOptionRules.MAXLENGTH]: 250,
        },
        [Validator.Rules.email]: true,
      },
      retailerId: {
        [Validator.Rules.required]: true,
      },
      regionId: {
        [Validator.Rules.required]: true,
      },
      role: {
        [Validator.Rules.required]: true,
      },
    });
  }

  public validate(fieldsToValidate: IPendingField[]): IValidationResult {
    const validationResults: IValidationResult = this._validator.validate(fieldsToValidate);

    return validationResults;
  }

  public getOptions(optionsData: IRetailer[]): void {
    const options: DropdownOption[] = optionsData.map((x: IRetailer) => {
      return {
        value: x.id,
        label: x.externalIdentifier,
      };
    });

    const roleOptions: DropdownOption[] = getNumericalKeys(Claims).filter(x => x > 1).map((x: any) => {
      return {
        value: x,
        label: Claims[x],
      };
    });

    this._roleOptions = roleOptions;
    this._options = options;
  }

  public isValid(isAdmin: boolean): boolean {
    const fieldsToValidate = this.getFieldsToValidate(isAdmin);
    const validationResults = this.validate(fieldsToValidate);

    for (const field of validationResults.fields) {
      if (!field.valid) {
        const state = {
          [field.name]: {
            value: (this._createUserComponentInstance.state as any)[field.name].value,
            error: field.errorMessage,
          },
        };
        this._createUserComponentInstance.setState(state);
      }
    }
    return validationResults.isValid;
  }

  public getCreateUserFormConfig(): ICreaterUserFormConfig {
    return {
      headingText: this._headingText,
      isLoading: this._createUserComponentInstance.state.isLoading,
      pageMode: this._createUserComponentInstance.pageMode,
    };
  }

  public getCreateUserFormBreadCrumb(): ICreateUserFormBreadCrumb {
    return {
      segments: this.getSegments(),
      onHomeClick: (e: any): void => {
        this._createUserComponentInstance.navigateTo('/admin/home')(e);
      },
      onRedirect: (event: any): void => {
        this._createUserComponentInstance.navigateTo()(event);
      },
    };
  }

  public getCreateUserForm(retailerId: string | number, regionOptions: IRegion[]): ICreateUserFormForm {
    const state: ICreateUserState = this._createUserComponentInstance.state;

    return {
      fields: {
        firstname: {
          error: state.firstname.error,
          value: state.firstname.value,
          onChange: (e: any): void => {
            this._createUserComponentInstance.setState({ firstname: { value: e, error: '' } });
          },
        },
        lastname: {
          error: state.lastname.error,
          value: state.lastname.value,
          onChange: (e: any): void => {
            this._createUserComponentInstance.setState({ lastname: { value: e, error: '' } });
          },
        },
        emailAddress: {
          error: state.emailAddress.error,
          value: state.emailAddress.value,
          onChange: (e: any): void => {
            this._createUserComponentInstance.setState({ emailAddress: { value: e, error: '' } });
          },
        },
        retailerId: {
          error: state.retailerId.error,
          value: this._createUserComponentInstance.getSelectedRetailerValue(),
          onChange: this._createUserComponentInstance.handleRetailerDropdown,
          options: this._options,
        },
        role: {
          error: state.role.error,
          value: this._createUserComponentInstance.getSelectedRoleValue(),
          onChange: this._createUserComponentInstance.handleRoleDropdown,
          options: this._roleOptions,
        },
        regionId: {
          error: state.regionId.error,
          value: this._createUserComponentInstance.getSelectedRegionValue(),
          onChange: this._createUserComponentInstance.handleRegionDropdown,
          options: [
            { value: -1, label: 'All' },
            ...regionOptions.map((region: IRegion) => ({
              value: region.id,
              label: getNormalizedEnumString(region.name),
            }))],
        },
      },
      actions: {
        onSubmit: (event: any): Function => {
          return this._createUserComponentInstance.postToApi(event);
        },
        cancel: {
          text: 'Cancel',
          action: (event: any): void => {
            this._createUserComponentInstance
              .navigateTo(this._createUserComponentInstance.pageMode === PageMode.Admin
                ? '/admin/admin-users'
                : `/admin/users${retailerId ? `?retailer=${retailerId}` : ''}`)(event);
          },
        },
        create: {
          text: this._createButtonText,
          isDisabled: this._createUserComponentInstance.state.isDisabledOnSubmit,
        },
        onStoreSelected: (selectedStores: IRetailerStore[]): void => {
          this._createUserComponentInstance.setState({ userStores: selectedStores });
        },
      },
    };
  }

  private setCreateOrUpdateText(isAdmin: boolean): void {
    if (this._createUserComponentInstance.userToEdit) {
      this._headingText = isAdmin ? 'Update Staff' : 'Update User';
      this._createButtonText = 'Update';
    } else {
      this._headingText = isAdmin ? 'Create Staff' : 'Create User';
      this._createButtonText = 'Create';
    }
  }

  private getFieldsToValidate(isAdmin: boolean): any[] {
    const state: ICreateUserState = this._createUserComponentInstance.state;

    const fieldsToValidate = [
      { name: 'firstname', value: state.firstname.value },
      { name: 'lastname', value: state.lastname.value },
      { name: 'emailAddress', value: state.emailAddress.value },
    ];

    if (!isAdmin) {
      const value: string = state.retailerId.value === null ? '' : state.retailerId.value.toString();
      fieldsToValidate.push({ value, name: 'retailerId' });
    }

    return fieldsToValidate;
  }

  private getSegments(): any[] {
    return [
      {
        key: 0,
        text: this._createUserComponentInstance.pageMode === PageMode.Admin
          ? 'Staff Management'
          : 'User Management',
        url: this._createUserComponentInstance.pageMode === PageMode.Admin
          ? '/admin/admin-users'
          : '/admin/users',
      },
      {
        key: 1,
        text: this._headingText,
        url: this.getCreateUpdateUrl(),
      },
    ];
  }

  private getCreateUpdateUrl(): string {
    const user: ICreatedUser = this._createUserComponentInstance.userToEdit;
    const pageMode: string = this._createUserComponentInstance.pageMode;
    let url: string;

    if (user && pageMode === PageMode.User) {
      url = `/admin/users/update/${user.userId}`;
    } else if (user && pageMode === PageMode.Admin) {
      url = `/admin/admin-users/update/${user.userId}`;
    } else if (!user && pageMode === PageMode.User) {
      url = '/admin/users/create';
    } else {
      url = '/admin/admin-users/create';
    }

    return url;
  }
}
