import { Action } from 'redux';
import { getHomeTopOffers, ITopOffersResponse } from '../api/productsApi';
import { IStoreState } from '../types';
import { store } from '../store';
import { AxiosResponse } from 'axios';

export const GET_HOME_TOP_OFFERS_REQUEST = 'GET_HOME_TOP_OFFERS_REQUEST';
export type GET_HOME_TOP_OFFERS_REQUEST = typeof GET_HOME_TOP_OFFERS_REQUEST;
export const GET_HOME_TOP_OFFERS_RESPONSE = 'GET_HOME_TOP_OFFERS_RESPONSE';
export type GET_HOME_TOP_OFFERS_RESPONSE = typeof GET_HOME_TOP_OFFERS_RESPONSE;
export const GET_HOME_TOP_OFFERS_ERROR = 'GET_HOME_TOP_OFFERS_ERROR';
export type GET_HOME_TOP_OFFERS_ERROR = typeof GET_HOME_TOP_OFFERS_ERROR;

export interface IGetTopOffersResponse extends Action {
  type: GET_HOME_TOP_OFFERS_RESPONSE;
  response: ITopOffersResponse;
}

export interface IGetTopOffersError extends Action {
  type: GET_HOME_TOP_OFFERS_ERROR;
  error?: string;
}

export const getHomeTopOffersRequest = (): any => {
  const state: IStoreState = store.getState();

  if (state.homeTopOffers !== undefined
    && state.catalogueMeta.meta.updatedAt < state.homeTopOffers.UpdatedAt) {
    return {
      type: GET_HOME_TOP_OFFERS_RESPONSE,
      response: state.homeTopOffers,
    };
  }

  return (dispatch: any) => {
    dispatch({
      type: GET_HOME_TOP_OFFERS_REQUEST,
    });

    return getHomeTopOffers()
      .then((response: AxiosResponse<ITopOffersResponse>) => {
        if (response.status === 200) {

          dispatch({
            type: GET_HOME_TOP_OFFERS_RESPONSE,
            response: {
              data: response.data.topOffers,
              UpdatedAt: new Date(),
              loading: false,
              error: '',
            },
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_HOME_TOP_OFFERS_ERROR,
});
