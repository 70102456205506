import React, { useEffect, useState } from 'react';
import { FullPageLayout } from '../../layouts';
import { useQuery } from '@tanstack/react-query';
import { LoadingThrobber } from '../../components';
import { QUERY_KEYS } from '../../constants';
import styles from './Statements.module.scss';
import {
  downloadFinancialBlob,
  FinanciaInvoiceTypeNumber,
  getFinancialStatements,
  IFinancialDocumentsResponse,
} from '../../api/FinancialDocuments/financialDocumentsApi';
import Table from '../../components/Table/Table';
import { IFinancialStatement, IRetailerStore, IRetailerStores, IStoreState } from '../../types';
import { Pagination } from 'react-bootstrap';
import { Button, Dropdown } from '@orderly/morrisons-component-library';
import { formatCurrencyLocale } from '../../helpers/Format/formatCurrency';
import { formatShortDateString } from '../../helpers/Format';
import { GenerateDownload } from '../../helpers/DownloadHelper';
import { connect } from 'react-redux';

const itemsPerPage = 25;
interface IProps {
  userStores: IRetailerStores;
}

function Statements({ userStores }: Readonly<IProps>): JSX.Element {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedStore, setSelectedStore] = useState<number>(null);

  const { data: statements, isLoading } = useQuery<IFinancialDocumentsResponse<IFinancialStatement>, Error>(
    [QUERY_KEYS.FINANCIAL_STATEMENTS, selectedStore, currentPage],
    () => getFinancialStatements({
      externalStoreId: selectedStore,
      pageSize: itemsPerPage,
      startIndex: (currentPage - 1) * itemsPerPage,
    }),
    {
      enabled: selectedStore !== null,
    },
  );

  useEffect(
    () => {
      if (userStores && userStores.stores.length > 0) {
        setSelectedStore(userStores.stores[0].externalId);
      }
    },
    [userStores],
  );

  useEffect(() => {
    if (statements && statements.data) {
      if (statements.data.length > 0) {
        setTotalPages(Math.ceil(statements.totalDocumentsCount / itemsPerPage));
      }
    }
  }, [statements, currentPage]);

  const onStatementDownload = async (documentId: number, type: number, selectedStore: number) => {
    const result = await downloadFinancialBlob(documentId, type, selectedStore);

    if (result) {
      GenerateDownload(result.invoice.data, result.invoice.fileName);
    }
  };

  const selectStore = (externalStoreId: number) => {
    setCurrentPage(1);
    setSelectedStore(externalStoreId);
  };

  return (
    <FullPageLayout
      heading="Statements"
      breadcrumbs={[
        {
          key: 0,
          text: 'Statements',
          url: '/statements',
        },
      ]}
      homeRoute="/home"
      headerAdditionalContent={
        (userStores != null && userStores.stores != null && userStores.stores.length > 1) &&
        <Dropdown
          className={styles.storeSelect}
          label=""
          name="store-sort"
          onChange={value => selectStore(value.value as number)}
          selectedValue={selectedStore}
          options={userStores.stores.map((store: IRetailerStore) => {
            return {
              value: store.externalId,
              label: `${store.name} [${store.externalId}]`,
            };
          })}
        />
      }
    >
      <div className={styles.container}>
        {isLoading && <LoadingThrobber />}
        {!isLoading && (
          <>
            <Table className="">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Statement No.</th>
                  <th>Total Invoices This Week</th>
                </tr>
              </thead>
              <tbody>
                {
                  statements &&
                  statements.data &&
                  statements.data.map((statement: IFinancialStatement) => {
                    return (
                      <tr key={statement.statementNo}>
                        <td>
                          {formatShortDateString(statement.statementDate.toString())}</td>
                        <td
                          className={styles.invoiceNo}
                          onClick={() => onStatementDownload(
                            statement.id,
                            FinanciaInvoiceTypeNumber.STATEMENT,
                            selectedStore)
                          }
                        >
                          <a href={statement.fileName} rel="noreferrer" target="_blank">{statement.statementNo}</a>
                        </td>
                        <td>
                          {formatCurrencyLocale(statement.totalInvoicesThisWeek)}
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
            {
              totalPages > 1 &&
              <Pagination className={styles.tablePagination}>
                <Button
                  className="tertiary"
                  type="submit"
                  text="Previous"
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                />
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  className="tertiary"
                  type="submit"
                  text="Next"
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            }
          </>
        )}
      </div>
    </FullPageLayout>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    userStores: state.userStores.data,
  };
};

export default connect(mapStateToProps)(Statements);
