import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import './OrderDispatch.scss';
import {
  IApiRequest,
  IStoreState,
  IUserDetails,
  IOrderDispatch,
  IOrderDispatchItem,
} from '../../../types';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { isAdmin, isLogisticsTeam } from '../../../helpers/Users/UserHelper';
import { toastr } from 'react-redux-toastr';
import FormatDateHelper from '../../../helpers/Format/Date/FormatDateHelper';
import Modal from '../../../components/Modal/Modal';
import { IExportLogisticsConfirmationFormParams } from '../../../api/Orders/orderDispatchApi';
import {
  submitOrderDispatchRequest,
  importOrderDispatchRequest,
  exportOrderDispatchRequest,
  getOrderDispatchRequest,
  saveOrderDispatchRequest,
} from '../../../actions/Orders/dispatchOrdersAction';
import {
  IImportLogisticsConfirmationFormParams,
} from '../../../api/LogisticsOrderDispatch/IImportLogisticsConfirmationFormParams';
import { arrayBufferToBase64 } from '../../../helpers/ArrayBufferHelper';
import { formatCurrency } from '../../../helpers/Format';
import { FullPageLayout } from '../../../layouts';
import SearchBar from '../../../components/SearchBar';

interface IOrderDispatchProps {
  userDetails: IUserDetails;
  dispatchOrders: IApiRequest<IOrderDispatch[]>;
  getDispatchOrders: () => void;
  submitDispatchOrder: (order: IOrderDispatch) => void;
  saveDispatchOrder: (order: IOrderDispatch) => void;
  exportDispatchOrder: (
    parameters: IExportLogisticsConfirmationFormParams,
    onSuccess: (data: any) => void,
  ) => void;
  importDispatchOrder: (
    parameters: IImportLogisticsConfirmationFormParams,
    onSuccess: (data: string[]) => void,
  ) => void;
}

const OrderDispatch = ({
  userDetails,
  dispatchOrders,
  getDispatchOrders,
  submitDispatchOrder,
  saveDispatchOrder,
  exportDispatchOrder,
  importDispatchOrder,
}: IOrderDispatchProps) => {
  const [orders, setOrders] = useState<IOrderDispatch[]>([]);
  const [showImportResponseModal, setShowImportResponseModal] = useState<boolean>(false);
  const [importResults, setImportResults] = useState<string[]>([]);
  const importLogisticsConfirmationFormElement = useRef(null);
  const [filteredOrders, setFilteredOrders] = useState<IOrderDispatch[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getDispatchOrders();
  }, []);

  useEffect(() => {
    if (dispatchOrders.data) {
      const updatedOrders = dispatchOrders.data.map((order) => {
        const sortedItems = order.items.sort((a, b) => (a.nationalDepotNo || 0) - (b.nationalDepotNo || 0));
        return {
          key: `${order.orderNumber}`,
          ...order,
          orderDate: FormatDateHelper.formatDate(new Date(order.orderDate)),
          deliveryDate: FormatDateHelper.formatDate(new Date(order.deliveryDate)),
          items: sortedItems.map((item, index) => ({ ...item, index: index + 1 })),
        };
      });

      setOrders(updatedOrders);
      setFilteredOrders([...updatedOrders]);
    }
  }, [dispatchOrders.data]);

  useEffect(() => {
    setFilteredOrders([...orders.filter(x => x.orderNumber.toLowerCase().includes(search.toLowerCase()))]);
  }, [search]);

  const isQuantityValid = (value: any, minValue: number, maxValue: number, errorMessage: string): boolean => {
    if (isNaN(parseInt(value, 10)) || value < minValue || value > maxValue) {
      toastr.error('Save Error', errorMessage, { timeOut: 10000 });
      return false;
    }
    return true;
  };

  const updateOrderInList = (updatedOrder: IOrderDispatch, column: string): void => {
    const index = orders.findIndex(x => x.orderNumber === updatedOrder.orderNumber);
    if (index !== -1) {
      const updatedOrders = [...orders];
      updatedOrder.updateId = `${updatedOrder.id}-${column}`;
      updatedOrders[index] = updatedOrder;
      setOrders(updatedOrders);
    }
  };

  const editFormatter = (cell: any, row: any, title: string): JSX.Element => {
    return (
      <div
        title={`Edit ${title}`}
        className={`edit-cell pointer ${cell != null ? 'populated' : ''}`}
      >
        <div className="edit-cell-value">
          {cell != null ? cell : 'Edit'}
        </div>
        {cell != null ? <div className="icon icon-Edit ml-1" /> : <></>}
      </div>
    );
  };

  const canUpdateOrder = (order: IOrderDispatch) =>
    order.trailerNumber !== null &&
    order.bayNumber !== null &&
    order.items.every(
      item => item.confirmedShippedCases !== null && item.confirmedShippedCases >= 0,
    );

  const onSubmitOrder = (order: IOrderDispatch) => {
    setTimeout(() => {
      const confirmationMessage = 'Are you sure you want to submit this order?';
      if (window.confirm(confirmationMessage)) {
        submitDispatchOrder(order);
      }
    }, 500);
  };

  const onExportOrder = (row: IOrderDispatch): void => {
    exportDispatchOrder(
      {
        orderNumber: row.orderNumber,
      },
      (response: any) => {
        const file = response.logisticsConfirmationForm;
        const element = document.createElement('a');
        element.setAttribute('href', file.content);
        element.setAttribute('download', file.filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
    );
  };

  const onFormImport = (
    element: React.MutableRefObject<HTMLInputElement>,
    importFunction: (
      parameters: IImportLogisticsConfirmationFormParams,
      onSuccess: (data: string[]) => void,
    ) => void,
  ): void => {
    const [file] = element.current.files;
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const fileData = fileReader.result;
      const parameters: IImportLogisticsConfirmationFormParams = {
        data: arrayBufferToBase64(fileData as ArrayBuffer),
      };
      importFunction(parameters, (results: string[]) => {
        element.current.value = null;
        if (results.length > 0) {
          setImportResults(results);
          setShowImportResponseModal(true);
        }
      });
    };

    if (file) {
      fileReader.readAsArrayBuffer(file);
    }
  };

  const columns = [
    {
      dataField: 'poNumber',
      text: 'PO Number',
      editable: false,
      headerClasses: 'poNumber',
    },
    {
      dataField: 'orderNumber',
      text: 'Order Number',
      editable: false,
      headerClasses: 'order-number',
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      editable: false,
      headerClasses: 'customerName',
    },
    {
      dataField: 'storeNumber',
      text: 'Store Number',
      editable: false,
      headerClasses: 'storeNumber',
    },
    {
      dataField: 'orderDate',
      text: 'Order Date',
      editable: false,
      headerClasses: 'orderDate',
    },
    {
      dataField: 'deliveryDate',
      text: 'Requested Delivery Date',
      editable: false,
      headerClasses: 'deliveryDate',
    },
    {
      dataField: 'depotNumber',
      text: 'Depot No',
      editable: false,
      headerClasses: 'depotNumber',
    },
    {
      dataField: 'nationalDepot',
      text: 'National Depot',
      editable: false,
      headerClasses: 'nationalDepot',
    },
    {
      dataField: 'trailerNumber',
      text: 'Trailer Number',
      editable: true,
      editor: {
        type: Type.NUMBER,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      minValue: 0,
      formatter: (cell: any, row: any) => editFormatter(cell, row, 'trailer number'),
      headerClasses: 'trailerNumber',
    },
    {
      dataField: 'bayNumber',
      text: 'Bay Number',
      editable: true,
      editor: {
        type: Type.NUMBER,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => editFormatter(cell, row, 'bay number'),
      maxValue: 255,
      minValue: 0,
      errorMessage: 'Bay number must be a valid number between 0 and 255',
      headerClasses: 'bayNumber',
    },
    {
      dataField: 'updateId',
      text: 'Actions',
      editable: false,
      headerClasses: 'action',
      formatter: (cell: any, row: IOrderDispatch) => {
        return (
          <div className="actions-cell">
            <div>
              <span title="Save Progress">
                <button
                  type="button"
                  onClick={(): void => saveDispatchOrder(row)}
                  disabled={!row.updateId}
                  className={`${!row.updateId ? 'btn-secondary' : 'btn-success'
                    } btn action-btn icon-floppy-disk`}
                />
              </span>
            </div>
            <div>
              <span title={!canUpdateOrder(row) ? 'Invalid cells detected' : 'Dispatch Order'}>
                <button
                  type="button"
                  onClick={(): void => onSubmitOrder(row)}
                  disabled={!canUpdateOrder(row)}
                  className={`${!canUpdateOrder(row) ? 'btn-secondary' : 'btn-success'
                    } btn action-btn icon-tick`}
                />
              </span>
            </div>
            {isAdmin(userDetails) || isLogisticsTeam(userDetails) ? (
              <>
                <div>
                  <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ref={importLogisticsConfirmationFormElement}
                    style={{ display: 'none' }}
                    onChange={() =>
                      onFormImport(importLogisticsConfirmationFormElement, importDispatchOrder)
                    }
                    aria-label="Import logistics confirmation form"
                  />
                  <span title="Import Order Form">
                    <button
                      type="button"
                      onClick={(): void => importLogisticsConfirmationFormElement.current.click()}
                      className="btn btn-primary action-btn icon-cloud-upload"
                    />
                  </span>
                </div>
                <div>
                  <span title="Export Order Form">
                    <button
                      type="button"
                      onClick={() => onExportOrder(row)}
                      className="btn btn-primary action-btn icon-Download"
                    />
                  </span>
                </div>
              </>
            ) : null}
          </div>
        );
      },
    },
  ];

  const subColumns = [
    {
      dataField: 'index',
      text: '#',
      editable: false,
    },
    {
      dataField: 'innerBarcode',
      text: 'Inner Barcode',
      editable: false,
    },
    {
      dataField: 'itemId',
      text: 'Pin',
      editable: false,
    },
    {
      dataField: 'description',
      text: 'Item Description',
      editable: false,
      headerClasses: 'description',
    },
    {
      dataField: 'nationalDepotNo',
      text: 'Regional/National',
      editable: false,
      headerClasses: 'depot',
    },
    {
      dataField: 'tag2StoreNumber',
      text: 'Tag 2 Store Number',
      editable: false,
    },
    {
      dataField: 'caseSize',
      text: 'Case Size',
      editable: false,
    },
    {
      dataField: 'allocatedCases',
      text: 'Allocated Cases',
      editable: false,
    },
    {
      dataField: 'casePrice',
      text: 'Case Price',
      editable: false,
      formatter: (cell: any) => formatCurrency(cell),
    },
    {
      dataField: 'confirmedShippedCases',
      text: 'Confirmed Shipped',
      headerClasses: 'w-10',
      editable: true,
      editor: {
        type: Type.NUMBER,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      errorMessage: 'Confirmed shipped cases must be between 0 and the allocated cases',
      minValue: 0,
      formatter: (cell: any, row: any) => editFormatter(cell, row, 'confirmed shipped'),
    },
    {
      dataField: 'pallets',
      text: 'Pallets',
      editable: false,
    },
  ];

  const expandRow: any = {
    renderer: (order: IOrderDispatch) => (
      <BootstrapTable
        classes="order-dispatch-table items"
        keyField="itemId"
        columns={subColumns}
        data={order.items}
        hover
        cellEdit={cellEditFactory({
          mode: 'click',
          blurToSave: true,
          afterSaveCell: (oldValue: any, newValue: any, row: IOrderDispatchItem, column: any) => {
            const { dataField, minValue, errorMessage } = column;
            const maxValue = row.allocatedCases;

            const isValid = isQuantityValid(newValue, minValue, maxValue, errorMessage);

            if (!isValid) {
              row.confirmedShippedCases = oldValue;
            } else {
              updateOrderInList(order, `${row.itemId}-${dataField}`);
            }
          },
        })}
        headerClasses="table-sub-header"
        noDataIndication="No items in the order"
      />
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => '',
    expandColumnRenderer: ({ expanded }: { expanded: any }) => (
      <div
        className={`icon-arrow-down expand ${expanded ? 'icon-arrow-down' : 'icon-arrow-right right'
          }`}
      />
    ),
  };

  return (
    <FullPageLayout
      heading="Order Dispatch"
      breadcrumbs={[
        {
          key: 0,
          text: 'Order Dispatch',
          url: '/admin/order-dispatch',
        },
      ]}
      homeRoute="/admin/home"
    >
      <div className="order-dispatch-container">
        <SearchBar
          label="Search Orders"
          value={search}
          onChange={value => setSearch(value)}
        />
        {dispatchOrders.loading && <LoadingThrobber />}
        <BootstrapTable
          classes="order-dispatch-table"
          keyField="orderNumber"
          columns={columns}
          expandRow={expandRow}
          data={filteredOrders}
          noDataIndication="No orders to review at this time"
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell: (oldValue: any, newValue: any, row: IOrderDispatch, column: any) => {
              const { dataField, minValue, errorMessage } = column;
              const maxValue = 255;

              const resetValue = (field: string) => {
                row[field] = oldValue;
              };

              const validateTrailerNumber = (value: string) => {
                if (value.length > 25) {
                  resetValue('trailerNumber');
                  toastr.error('Error', 'Trailer number must be a maximum of 25 characters', {
                    timeOut: 10000,
                  });
                  return false;
                }
                return true;
              };

              const validateBayNumber = (value: any) => {
                const isValid = isQuantityValid(value, minValue, maxValue, errorMessage);
                if (!isValid) {
                  resetValue('bayNumber');
                }
                return isValid;
              };

              let isValid = true;

              switch (dataField) {
                case 'trailerNumber':
                  isValid = validateTrailerNumber(newValue);
                  break;
                case 'bayNumber':
                  isValid = validateBayNumber(newValue);
                  break;
              }

              if (isValid) {
                updateOrderInList(row, column.dataField);
              }
            },
          })}
          headerClasses="table-header"
        />
      </div>
      <Modal
        header="Import Results"
        isOpen={showImportResponseModal}
        onClose={() => setShowImportResponseModal(false)}
      >
        <ul>
          {importResults.map((result, index) => (
            <li key={index}>{result}</li>
          ))}
        </ul>
      </Modal>
    </FullPageLayout>

  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    dispatchOrders: state.orderDispatch,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getDispatchOrders: () => dispatch(getOrderDispatchRequest()),
  submitDispatchOrder: (order: IOrderDispatch) => dispatch(submitOrderDispatchRequest(order)),
  saveDispatchOrder: (order: IOrderDispatch) => dispatch(saveOrderDispatchRequest(order)),
  exportDispatchOrder: (
    parameters: IExportLogisticsConfirmationFormParams,
    onSuccess: (data: any) => void,
  ) => dispatch(exportOrderDispatchRequest(parameters, onSuccess)),
  importDispatchOrder: (
    parameters: IImportLogisticsConfirmationFormParams,
    onSuccess: (data: string[]) => void,
  ) => dispatch(importOrderDispatchRequest(parameters, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDispatch);
