import React, { useState, Dispatch, useEffect, useMemo } from 'react';
import { PageMode } from '../../ManageUsers/enums/ManageUsers.enum';
import LoadingThrobber from '../../../../components/LoadingThrobber/LoadingThrobber';
import { Breadcrumbs, TextInput, Card, Dropdown, Button, Tabs, Tab, Checkbox } from '@orderly/morrisons-component-library';
import { ICreateUserFormProps } from './interfaces/CreateUserForm.interface';
import { connect } from 'react-redux';
import { IStoreState, IRetailerStore } from '../../../../types';
import { IGetUserStoresParams } from '../../../../api/userApi';
import { getUserStoresRequest, clearData } from '../../../../actions/Users/userStoresAction';

const CreateUserForm = ({
  config,
  breadCrumb,
  form,
  retailerDetails,
  userId,
  getUserStores,
  userStores,
  clearState }: ICreateUserFormProps) => {

  const [stores, setStores] = useState([] as IRetailerStore[]);
  const [selectedStores, setSelectedStores] = useState([] as IRetailerStore[]);

  // Filter Values
  const [filteredStoresFilter, setFilteredStoresFilter] = useState('');
  const [selectedFilteredStoresFilter, setSelectedFilteredStoresFilter] = useState('');

  useEffect(
    (): void => {
      clearState();
      setStores([]);
      setSelectedStores([]);
    },
    [userId],
  );

  useEffect(
    (): void => {
      getUserStores({
        UserId: userId,
      }).then(
        (): void => {
        },
        (e: any): void => {
          console.log('An error has occurred', e);
        },
      );
    },
    [userId],
  );

  useEffect(
    (): void => {
      if (retailerDetails && userStores.data != null && userStores.data.stores != null) {
        const currentRetailer = retailerDetails.filter(x => x.id === form.fields.retailerId.value);
        if (currentRetailer[0] != null) {
          const unique = currentRetailer[0].stores.filter((store) => {
            return userStores.data.stores.findIndex(x => x.id === store.id) === -1;
          });
          setStores(unique);
          setSelectedStores(userStores.data.stores);
        }
      }
    },
    [retailerDetails, userStores, form.fields.retailerId.value],
  );

  useEffect(
    (): void => {
      setSelectedStores([]);
    },
    [form.fields.retailerId.value],
  );

  useEffect(
    (): void => {
      form.actions.onStoreSelected(selectedStores);
    },
    [selectedStores],
  );

  const selectStore = (checkboxId: string) => {
    if (stores) {
      const storeId = parseInt(checkboxId, 0);
      const tempStores = stores.filter(x => x.id === storeId);

      setSelectedStores(tempStores.concat(selectedStores).sort((a, b) => orderAlphabetically(a.name, b.name)));
      setStores(stores.filter(x => x.id !== storeId));
    }
  };

  const deselectStore = (checkboxId: string) => {
    if (stores) {
      const storeId = parseInt(checkboxId, 0);
      const tempStores = selectedStores.filter(x => x.id === storeId);

      setStores(tempStores.concat(stores).sort((a, b) => orderAlphabetically(a.name, b.name)));
      setSelectedStores(selectedStores.filter(x => x.id !== storeId));
    }
  };

  const deselectVisibleStores = () => {
    const temp = filteredSelectedStores.concat(stores).sort((a, b) => orderAlphabetically(a.name, b.name));
    const newStores = selectedStores.filter((store) => {
      return temp.findIndex(x => x.id === store.id) === -1;
    });

    setStores(temp);
    setSelectedStores(newStores);
    setSelectedFilteredStoresFilter('');
  };

  const selectVisibleStores = () => {
    const temp = filteredStores.concat(selectedStores).sort((a, b) => orderAlphabetically(a.name, b.name));
    const newStores = stores.filter((store) => {
      return temp.findIndex(x => x.id === store.id) === -1;
    });

    setStores(newStores);
    setSelectedStores(temp);
    setFilteredStoresFilter('');
  };

  const orderAlphabetically = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }

    if (a > b) {
      return 1;
    }
    return 0;
  };

  const filteredStores = useMemo(
    () => stores.filter((store) => {
      return `${store.name} (${store.externalId})`.toLowerCase().includes(filteredStoresFilter.toLowerCase())
      && selectedStores.findIndex(x => x.id === store.id) === -1;
    }),
    [stores, selectedStores, filteredStoresFilter],
  );

  const filteredSelectedStores = useMemo(
    () => selectedStores.filter((store) => {
      return `${store.name} (${store.externalId})`.toLowerCase().includes(selectedFilteredStoresFilter.toLowerCase());
    }),
    [selectedStores, selectedFilteredStoresFilter],
  );

  return (
    <div className="user-container">
      <Breadcrumbs
        className="breadcrumbs"
        onHomeClick={breadCrumb.onHomeClick}
        onRedirect={breadCrumb.onRedirect}
        segments={breadCrumb.segments}
      />
      <div className="create-user-container">
        {(config.isLoading || userStores.loading) && <LoadingThrobber />}
        <div className="header-container">
          <h3 className="create-user-heading">{config.headingText}</h3>
        </div>
        <form className="create-user-form" noValidate onSubmit={form.actions.onSubmit} >
          <div className="user-row">
            <Card className="user-details-column">
              <h4>Details</h4>
                {config.pageMode === PageMode.User &&
                  <Dropdown
                    placeholder="Select a company"
                    error={form.fields.retailerId.error}
                    label=""
                    name="retailerId"
                    onChange={form.fields.retailerId.onChange}
                    selectedValue={form.fields.retailerId.value}
                    options={form.fields.retailerId.options}
                    className="retailer-select"
                  />
                }
                {config.pageMode === PageMode.Admin &&
                  <Dropdown
                    placeholder="Select role"
                    error={form.fields.role.error}
                    label=""
                    name="role"
                    onChange={form.fields.role.onChange}
                    selectedValue={form.fields.role.value}
                    options={form.fields.role.options}
                    className="retailer-select"
                  />
                }
                <TextInput
                  error={form.fields.firstname.error}
                  label="First name"
                  name="firstname"
                  onChange={form.fields.firstname.onChange}
                  type="text"
                  value={form.fields.firstname.value as string}
                  className="firstname"
                />
                <TextInput
                  error={form.fields.lastname.error}
                  label="Last name"
                  name="lastname"
                  onChange={form.fields.lastname.onChange}
                  type="text"
                  value={form.fields.lastname.value as string}
                  className="lastname"
                />
                <TextInput
                  error={form.fields.emailAddress.error}
                  label="Email"
                  name="emailAddress"
                  onChange={form.fields.emailAddress.onChange}
                  type="email"
                  value={form.fields.emailAddress.value as string}
                  className="email-input"
                />
                {config.pageMode === PageMode.Admin &&
                  <Dropdown
                    placeholder="Select a region"
                    error={form.fields.regionId.error}
                    label=""
                    name="regionId"
                    onChange={form.fields.regionId.onChange}
                    selectedValue={form.fields.regionId.value}
                    options={form.fields.regionId.options}
                    className="retailer-select"
                  />
                }
            </Card>
            {config.pageMode === PageMode.User &&
              <Card className="user-selected-stores-column">
                <div className="store-header-container">
                    <h4>Selected Stores</h4>
                    <Button
                      text="Remove all"
                      type="button"
                      onClick={deselectVisibleStores}
                      className="tertiary small-button"
                    />
                </div>
                {
                  filteredSelectedStores &&
                  <div className="store-select-container">
                    <TextInput
                      label="Search selected stores"
                      name="searchSelected"
                      onChange={(val: string) => setSelectedFilteredStoresFilter(val)}
                      type="text"
                      value={selectedFilteredStoresFilter}
                      className="selected-stores-input"
                      error=""
                    />
                    <div className="table-container">
                      <table>
                        <tbody>
                          {
                            userStores.loading ? <tr><td>Loading...</td></tr> :
                            filteredSelectedStores.length === 0 ? <tr><td>No Stores Selected</td></tr> :
                            filteredSelectedStores.map((store: IRetailerStore) => {
                              return (
                                <tr key={store.id}>
                                <td>
                                <Checkbox
                                  checked={true}
                                  key={store.id}
                                  label={`${store.name} (${store.externalId})`}
                                  name={store.id.toString()}
                                  onChange={deselectStore}
                                  isSmall={true}
                                />
                                </td>
                              </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              </Card>
            }
            {config.pageMode === PageMode.User &&
              <Card className="user-unselected-stores-column">
                <div className="store-header-container">
                    <h4>Stores</h4>
                    <Button
                      text="Assign all"
                      type="button"
                      onClick={selectVisibleStores}
                      className="tertiary small-button"
                    />
                </div>
                {
                  filteredStores &&
                  <div className="store-select-container">
                    <TextInput
                      label="Search stores"
                      name="searchStores"
                      onChange={(val: string) => setFilteredStoresFilter(val)}
                      type="text"
                      value={filteredStoresFilter}
                      className="stores-input"
                      error=""
                    />
                    <div className="table-container">
                      <table>
                        <tbody>
                        {
                          filteredStores.length === 0 ? <tr><td>No Stores Selected</td></tr> :
                          filteredStores.map((store: IRetailerStore, index: number) => {
                            return (
                              <tr key={store.id}>
                              <td>
                              <Checkbox
                                checked={false}
                                key={store.id}
                                label={`${store.name} (${store.externalId})`}
                                name={store.id.toString()}
                                onChange={selectStore}
                                isSmall={true}
                              />
                              </td>
                            </tr>
                            );
                          })
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              </Card>
            }
          </div>
          <div className="form-controls">
            <div className="cancel-action-container">
              <Button
                className="create-user-action-cancel secondary"
                type="button"
                text={form.actions.cancel.text}
                onClick={form.actions.cancel.action}
              />
            </div>
            <div className="update-action-container">
              <Button
                className="create-user-action-submit tertiary"
                type="submit"
                text={form.actions.create.text}
                disabled={form.actions.create.isDisabled}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userStores: state.userStores,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getUserStores: (parameters: IGetUserStoresParams) => dispatch(getUserStoresRequest(parameters)),
    clearState: () => dispatch(clearData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserForm);
