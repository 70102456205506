import Fetch from './apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import queryString from 'query-string';
import { IGetProductPageParams } from './IGetProductPageParams';
import { IProductDetail, IPagedDataResponse, IProduct, IProductExclusion } from '../types';
import { IImportNationalProductDepotFormParams } from './NationalProductDepot/IImportNationalProductDepotFormParams';
import { IImportNationalProductDepotFormResponse } from './NationalProductDepot/IImportNationalProductDepotFormResponse';

export interface IGetProductPageResponse {
  products: IPagedDataResponse<IProduct[]>;
}
export interface IGetProductExclusionsResponse {
  exclusions: IProductExclusion[];
}
export interface IGetProductExclusionsParams {
  [key: string]: any;
  retailerId: number;
}

export interface IUpdateProductExclusionsParams {
  [key: string]: any;
  retailerId: number;
  productIds: number[];
}

export const getProductPage = (params: IGetProductPageParams): AxiosPromise<IGetProductPageResponse> => {
  return Fetch().then(axios => axios.post(params.isAdmin ? 'products/admin' : 'products', params));
};

export const getProductExclusions = (params: IGetProductExclusionsParams): AxiosPromise<IGetProductExclusionsResponse> => {
  return Fetch().then(axios => axios.get(`products/exclusions?${queryString.stringify(params)}`));
};

export const updateProductExclusions = (params: IUpdateProductExclusionsParams) => {
  return Fetch().then(axios => axios.post(`products/exclusions/update?RetailerId=${params.retailerId}`, params));
};

export const importNationalProductDepotForm =
(params: IImportNationalProductDepotFormParams) : AxiosPromise<IImportNationalProductDepotFormResponse> => {
  return Fetch().then(axios => axios.post('products/nationaldepotform/import', params));
};

export interface IGetTopOffersParams {
  [key: string]: any;
  categoryId: number;
}

export interface ITopOffersResponse {
  topOffers: IProduct[];
}

export const getTopOffers = (params: IGetTopOffersParams): AxiosPromise<ITopOffersResponse> => {
  return Fetch().then(axios => axios.get(`products/gettopoffers?${queryString.stringify(params)}`));
};

export const getHomeTopOffers = (): AxiosPromise<ITopOffersResponse> => {
  return Fetch().then(axios => axios.get('products/hometopoffers'));
};

export interface IGetProductParams {
  [key: string]: any;
  productId: number;
  categoryId: number;
}

export interface IGetProductResponse {
  product: IProductDetail;
}

export const getProduct = (params: IGetProductParams): AxiosPromise<IGetProductResponse> => {
  return Fetch().then(axios => axios.get(`product?${queryString.stringify(params)}`));
};
