import React, { Dispatch, useEffect, useState } from 'react';
import {
  IStoreState,
  ICategory,
  IUserDetails,
  IApiRequest,
  IRetailerDetails,
  IFolderContents,
  FolderType,
  BannerLocations,
  IProduct,
} from '../../types';
import { connect } from 'react-redux';
import { getCategoriesRequest } from '../../actions/categoriesAction';
import CategoryCarousel from '../../components/CategoryCarousel/CategoryCarousel';
import { Button } from '@orderly/morrisons-component-library';
import { formatDateString } from '../../helpers/Format';
import { navigateTo } from '../../routing/Navigation';
import getRetailerDetailsRequest from '../../actions/retailerDetailAction';
import { IGetFolderContentsParams } from '../../api/Documents/getFolderContentsApi';
import { getFolderContentsRequest } from '../../actions/Documents/folderContentsAction';
import './HomePage.scss';
import { BannerCarousel } from '../../components';
import SectionHeading from '../../components/SectionHeading/SectionHeading';
import ProductCarousel from '../../components/ProductCarousel/ProductCarousel';
import { getHomeTopOffersRequest } from '../../actions/homeTopOffersAction';

interface IHomePageProps {
  retailerDetails: IApiRequest<IRetailerDetails>;
  categories: IApiRequest<ICategory[]>;
  userDetails: IUserDetails;
  history: any;
  folderContents: IApiRequest<IFolderContents>;
  homeTopOffers: IApiRequest<IProduct[]>;
  getCategories: () => void;
  getRetailerDetails: () => void;
  getFolderContents: (params: IGetFolderContentsParams) => void;
}

const WEEKLY_BULLETINS_FOLDER = 'Weekly Bulletins';
const TRADING_PACKS_FOLDER = 'Trading Packs';

const HomePage = ({
  retailerDetails,
  categories,
  userDetails,
  history,
  folderContents,
  homeTopOffers,
  getCategories,
  getRetailerDetails,
  getFolderContents,
}: IHomePageProps) => {
  const [showBulletingsButton, setShowBulletinsButton] = useState(false);
  const [showTradingPacksButton, setShowTradingPacksButton] = useState(false);

  useEffect(() => {
    getCategories();
    getRetailerDetails();

    return () => {
      setShowBulletinsButton(false);
    };
  }, []);

  useEffect(() => {
    if (retailerDetails && retailerDetails.data) {
      getQuickLinkFolderContents();
    }
  }, [retailerDetails]);

  useEffect(() => {
    const { data } = folderContents;
    const { description, documents } = data;

    if (description && description.toLowerCase() === WEEKLY_BULLETINS_FOLDER.toLowerCase() && documents.length > 0) {
      setShowBulletinsButton(true);
    }

    if (description && description.toLowerCase() === TRADING_PACKS_FOLDER.toLowerCase() && documents.length > 0) {
      setShowTradingPacksButton(true);
    }
  }, [folderContents]);

  const getQuickLinkFolderContents = () => {
    getFolderContents(
      {
        folderPath: WEEKLY_BULLETINS_FOLDER,
        retailerId: retailerDetails.data.id,
        prefix: '',
        folderType: FolderType.CustomerGroup,
      },
    );

    getFolderContents(
      {
        folderPath: TRADING_PACKS_FOLDER,
        retailerId: retailerDetails.data.id,
        prefix: '',
        folderType: FolderType.CustomerGroup,
      },
    );
  };

  return (
    <div className="home-page-wrapper">
      <div className="welcome-back-wrapper">
        <section className="welcome-back card">
          <h2>Welcome back, <span className="userDetails">
            {userDetails.firstName}{userDetails.isImpersonating && ' (Impersonating)'}</span></h2>
          <div className="last-visited">
            Last time you were online: <span className="time">{formatDateString(userDetails.lastVisited)}</span>
          </div>
          <div className="button-wrapper">
            <Button
              type="button"
              className="link action-button homepage-button"
              history={history}
              text="View catalogue"
              icon="icon-basket"
              iconPos="left"
              onClick={() => navigateTo('/catalogue')}
            />
            <span className="icon-arrow-link-right" />
          </div>
          <div className="button-wrapper">
            <Button
              type="button"
              className="link action-button homepage-button"
              history={history}
              text="My documents"
              icon="icon-receipt"
              iconPos="left"
              onClick={() => navigateTo('/documents')}
            />
            <span className="icon-arrow-link-right" />
          </div>
          {
            showBulletingsButton &&
            <div className="button-wrapper">
              <Button
                type="button"
                className="link action-button homepage-button"
                history={history}
                text={WEEKLY_BULLETINS_FOLDER}
                icon="icon-receipt"
                iconPos="left"
                onClick={() => navigateTo(`/documents?folder=${encodeURI(WEEKLY_BULLETINS_FOLDER)}`)}
              />
              <span className="icon-arrow-link-right" />
            </div>
          }
          {
            showTradingPacksButton &&
            <div className="button-wrapper">
              <Button
                type="button"
                className="link action-button homepage-button"
                history={history}
                text={TRADING_PACKS_FOLDER}
                icon="icon-receipt"
                iconPos="left"
                onClick={() => navigateTo(`/documents?folder=${encodeURI(TRADING_PACKS_FOLDER)}`)}
              />
              <span className="icon-arrow-link-right" />
            </div>
          }
          <div className="button-wrapper">
            <Button
              type="button"
              className="link action-button homepage-button"
              history={history}
              text="My orders"
              icon="icon-credit-card"
              iconPos="left"
              onClick={() => navigateTo('/orders')}
            />
          </div>
        </section>
        <div className="homepage-carousel-container">
          <BannerCarousel
            controls={true}
            location={BannerLocations.Homepage}
          />
        </div>
      </div>
      {
        homeTopOffers.data && homeTopOffers.data.length > 0 &&
        <section className="top-offers-section">
          <SectionHeading>Top offers for you</SectionHeading>
          <ProductCarousel
            products={homeTopOffers.data}
            maxOrderQuantity={null}
          />
        </section>
      }
      {
        !categories.loading && categories.data &&
        <section className="category-section">
          <CategoryCarousel categories={categories} />
        </section>
      }
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    categories: state.categories,
    userDetails: state.userDetails,
    retailerDetails: state.retailerDetails,
    folderContents: state.documentsFolderContents,
    homeTopOffers: state.homeTopOffers,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCategories: () => dispatch(getCategoriesRequest()),
    getRetailerDetails: () => dispatch(getRetailerDetailsRequest()),
    getFolderContents: (parameters: IGetFolderContentsParams) => dispatch(getFolderContentsRequest(parameters, true)),
    getHomeTopOffers: () => dispatch(getHomeTopOffersRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
