import Fetch from './apiConfig/apiConfig';
import { AxiosInstance, AxiosResponse } from 'axios';
import { IRegion } from '../types';

export interface IRegionsResponse {
  regions: IRegion[];
}

export const getRegions = async (): Promise<AxiosResponse<IRegionsResponse>> => {
  const axiosInstance: AxiosInstance = await Fetch();
  return axiosInstance.get<IRegionsResponse>('supplychain/regions/get');
};
