import { Reducer } from 'redux';
import {
  GET_HOME_TOP_OFFERS_ERROR,
  GET_HOME_TOP_OFFERS_REQUEST,
  GET_HOME_TOP_OFFERS_RESPONSE,
} from '../actions/homeTopOffersAction';
import { IApiRequest, IProduct } from '../types';

const initialState: IApiRequest<IProduct[]> = {
  data: [],
  error: '',
  loading: false,
  UpdatedAt: null,
};

const homeTopOffersReducer: Reducer<IApiRequest<IProduct[]>> =
  (state: IApiRequest<IProduct[]> = initialState, action: any) => {
    switch (action.type) {
      case GET_HOME_TOP_OFFERS_RESPONSE: {
        return {
          ...state,
          ...action.response,
        };
      }
      case GET_HOME_TOP_OFFERS_REQUEST:
      case GET_HOME_TOP_OFFERS_ERROR:
      default: {
        return state;
      }
    }
  };

export default homeTopOffersReducer;
