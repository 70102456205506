import React, { Dispatch, useEffect, useState } from 'react';
import {
  editOrderPendingReviewRequest,
  getOrdersPendingReviewRequest,
  submitOrderReviewRequest,
} from '../../../actions/Orders/orderReviewAction';
import { IApiRequest, IPendingOrder, IStoreState } from '../../../types';
import { connect } from 'react-redux';
import OrderReviewView from '../../../components/OrderReviewView';
import { FullPageLayout } from '../../../layouts';

interface IOrdersProps {
  orderReview: IApiRequest<IPendingOrder[]>;
  getOrdersPendingReview: () => void;
  submitOrderReview: (orderId: number, approved: boolean) => Promise<any>;
  editOrderPendingReview: (order: IPendingOrder) => Promise<any>;
}

function Orders({
  orderReview,
  getOrdersPendingReview,
  submitOrderReview,
  editOrderPendingReview,
}: IOrdersProps): JSX.Element {
  const [localOrders, setLocalOrders] = useState<IPendingOrder[]>([]);

  useEffect(() => {
    getOrdersPendingReview();
  }, []);

  useEffect(() => {
    if (orderReview.data) {
      setLocalOrders(orderReview.data);
    }
  }, [orderReview]);

  const editOrder = (order: IPendingOrder) => {
    editOrderPendingReview(order)
      .then(() => getOrdersPendingReview())
      .catch(() => console.warn('An error has occured'));
  };

  function submitOrder(order: IPendingOrder, approved: boolean): void {
    const confirmationMessage = `Are you sure you want to ${approved ? 'approve' : 'reject'} this order?`;
    if (window.confirm(confirmationMessage)) {
      editOrder(order);

      submitOrderReview(order.id, approved)
        .then(() => getOrdersPendingReview())
        .catch(() => console.warn('An error has occured'));
    }
  }

  function saveOrder(order: IPendingOrder): void {
    setTimeout(() => {
      const confirmationMessage = 'Are you sure you want to save this order?';
      if (window.confirm(confirmationMessage)) {
        editOrder(order);
      }
    }, 500);
  }

  return (
    <FullPageLayout
      heading="Review Orders"
      breadcrumbs={[
        {
          key: 0,
          text: 'Review Orders',
          url: '/admin/orders',
        },
      ]}
      homeRoute="/admin/home"
    >
      <OrderReviewView
        loading={orderReview.loading}
        submitReviewAction={submitOrder}
        editOrderAction={saveOrder}
        orders={localOrders}
        setOrders={setLocalOrders}
      />
    </FullPageLayout>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    orderReview: state.orderReview,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getOrdersPendingReview: () => dispatch(getOrdersPendingReviewRequest()),
    submitOrderReview: (orderId: number, approved: boolean) => dispatch(submitOrderReviewRequest(orderId, approved)),
    editOrderPendingReview: (order: IPendingOrder) => dispatch(editOrderPendingReviewRequest(order)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
