import { Dispatch } from 'redux';
import { getOrdersPendingReviewApi, submitOrderReviewApi, editOrderPendingReviewApi } from '../../api/Orders/orderReviewApi';
import { toastr } from 'react-redux-toastr';
import { IPendingOrder } from '../../types';

export const GET_ORDERS_PENDING_REVIEW_REQUEST = 'GET_ORDERS_PENDING_REVIEW_REQUEST';
export type GET_ORDERS_PENDING_REVIEW_REQUEST = typeof GET_ORDERS_PENDING_REVIEW_REQUEST;

export const GET_ORDERS_PENDING_REVIEW_RESPONSE = 'GET_ORDERS_PENDING_REVIEW_RESPONSE';
export type GET_ORDERS_PENDING_REVIEW_RESPONSE = typeof GET_ORDERS_PENDING_REVIEW_RESPONSE;

export const GET_ORDERS_PENDING_REVIEW_ERROR = 'GET_ORDERS_PENDING_REVIEW_ERROR';
export type GET_ORDERS_PENDING_REVIEW_ERROR = typeof GET_ORDERS_PENDING_REVIEW_ERROR;

export const SUBMIT_ORDER_REVIEW_REQUEST = 'SUBMIT_ORDER_REVIEW_REQUEST';
export type SUBMIT_ORDER_REVIEW_REQUEST = typeof SUBMIT_ORDER_REVIEW_REQUEST;

export const SUBMIT_ORDER_REVIEW_SUCCESS = 'SUBMIT_ORDER_REVIEW_SUCCESS';
export type SUBMIT_ORDER_REVIEW_SUCCESS = typeof SUBMIT_ORDER_REVIEW_SUCCESS;

export const SUBMIT_ORDER_REVIEW_ERROR = 'SUBMIT_ORDER_REVIEW_ERROR';
export type SUBMIT_ORDER_REVIEW_ERROR = typeof SUBMIT_ORDER_REVIEW_ERROR;

export const EDIT_ORDER_PENDING_REVIEW_REQUEST = 'EDIT_ORDER_PENDING_REVIEW_REQUEST';
export type EDIT_ORDER_PENDING_REVIEW_REQUEST = typeof EDIT_ORDER_PENDING_REVIEW_REQUEST;

export const EDIT_ORDER_PENDING_REVIEW_SUCCESS = 'EDIT_ORDER_PENDING_REVIEW_SUCCESS';
export type EDIT_ORDER_PENDING_REVIEW_SUCCESS = typeof EDIT_ORDER_PENDING_REVIEW_SUCCESS;

export const EDIT_ORDER_PENDING_REVIEW_ERROR = 'EDIT_ORDER_PENDING_REVIEW_ERROR';
export type EDIT_ORDER_PENDING_REVIEW_ERROR = typeof EDIT_ORDER_PENDING_REVIEW_ERROR;

export const getOrdersPendingReviewRequest = (): any => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_ORDERS_PENDING_REVIEW_REQUEST,
    });

    try {
      const response = await getOrdersPendingReviewApi();

      if (response.status === 200) {
        const data = response.data;

        dispatch({
          type: GET_ORDERS_PENDING_REVIEW_RESPONSE,
          response: data,
        });
      } else {
        onGetOrdersPendingReviewError(dispatch);
      }
    } catch (error) {
      console.log(error);
      onGetOrdersPendingReviewError(dispatch);
    }
  };
};

const onGetOrdersPendingReviewError = (dispatch: Dispatch) => dispatch({
  type: GET_ORDERS_PENDING_REVIEW_ERROR,
});

export const submitOrderReviewRequest = (orderId: number, approved: boolean): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SUBMIT_ORDER_REVIEW_REQUEST,
    });

    try {
      const response = await submitOrderReviewApi({ orderId, approved });

      if (response.status === 200) {
        toastr.success(`Order ${approved ? 'Approved' : 'Rejected'}`,
         `Order has been ${approved ? 'approved' : 'rejected'}.`);
        dispatch({
          type: SUBMIT_ORDER_REVIEW_SUCCESS,
        });

        dispatch(getOrdersPendingReviewRequest());
      } else {
        onSubmitOrderReviewError(dispatch);
      }
    } catch (error) {
      console.log(error);
      onSubmitOrderReviewError(dispatch);
    }
  };
};

const onSubmitOrderReviewError = (dispatch: Dispatch) => dispatch({
  type: SUBMIT_ORDER_REVIEW_ERROR,
});

export const editOrderPendingReviewRequest = (order: IPendingOrder): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EDIT_ORDER_PENDING_REVIEW_REQUEST,
    });

    try {
      const response = await editOrderPendingReviewApi({ order });

      if (response.status === 200) {
        toastr.success('Order Saved', 'Order has been saved successfully.');
        dispatch({
          type: EDIT_ORDER_PENDING_REVIEW_SUCCESS,
        });
      } else {
        onEditOrderPendingReviewError(dispatch);
      }
    } catch (error) {
      console.log(error);
      onEditOrderPendingReviewError(dispatch);
    }
  };
};

const onEditOrderPendingReviewError = (dispatch: Dispatch) => dispatch({
  type: EDIT_ORDER_PENDING_REVIEW_ERROR,
});
