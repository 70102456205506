import Fetch from '../apiConfig/apiConfig';
import { IDocumentBlob, IFinancialInvoice, IFinancialStatement } from '../../types';
import queryString from 'query-string';

export interface IFinancialDocumentsResponse<T> {
  totalDocumentsCount: number;
  data: T[];
}

export interface IInvoicesResponse {
  invoices: IFinancialDocumentsResponse<IFinancialInvoice>;
}

export interface IStatementsResponse {
  statements: IFinancialDocumentsResponse<IFinancialStatement>;
}

interface IFinancialBlobResponse {
  invoice: IDocumentBlob;
}

interface IFinancialInvoicesRequest {
  externalStoreId: number;
  invoiceType: number;
  startIndex: number;
  pageSize: number;
}

interface IFinancialStatementsRequest {
  externalStoreId: number;
  startIndex: number;
  pageSize: number;
}

export const FinancialDocumentTypeString: Record<string, string> = {
  INVOICE: 'Invoices',
  REBATE: 'Rebates',
  CREDIT_NOTE: 'Credit Notes',
};

export enum FinanciaInvoiceTypeNumber {
  'INVOICE' = 0,
  'REBATE' = 1,
  'CREDIT_NOTE' = 2,
  'STATEMENT' = 3,
  'ALL' = -1,
}

export const getFinancialStatements = async (
  params: IFinancialStatementsRequest,
): Promise<IFinancialDocumentsResponse<IFinancialStatement>> => {
  const axios = await Fetch();
  const { data } = await axios.get<IStatementsResponse>(`statements/get?${queryString.stringify(params)}`);

  return data.statements;
};

export const getFinancialInvoices = async (
  params: IFinancialInvoicesRequest,
): Promise<IFinancialDocumentsResponse<IFinancialInvoice>> => {
  const axios = await Fetch();
  const { data } = await axios.get<IInvoicesResponse>(`invoices/get?${queryString.stringify(params)}`);

  return data.invoices;
};

export const downloadFinancialBlob =
  async (id: number, type: FinanciaInvoiceTypeNumber, externalStoreId: number): Promise<IFinancialBlobResponse> => {
    const axios = await Fetch();
    const { data } = await axios.get<IFinancialBlobResponse>(
      `financial-documents/blob?documentId=${id}&type=${type}&externalStoreId=${externalStoreId}`,
    );

    return data;
  };
