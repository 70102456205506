import React from 'react';
import './PersonalDetailsCard.scss';
import { Button } from '@orderly/morrisons-component-library';
import { IRegion, IStoreState, IUserDetails } from '../../types';
import { navigateTo } from '../../routing/Navigation';
import { connect } from 'react-redux';
import { isAccountManager, isAdmin } from '../../helpers/Users/UserHelper';
import { getNormalizedEnumString } from '../../helpers/EnumHelper';

interface IPersonalDetailsCardProps {
  userDetails: IUserDetails;
  regions: IRegion[];
}

const PersonalDetailsCard = ({
  userDetails,
  regions,
}: IPersonalDetailsCardProps) => {
  return (
    <div className="card">
      <div className="personal-details">
        <h4>Personal Details</h4>
        <div>
          <span className="icon icon-user" />
          <span>{`${userDetails.firstName} ${userDetails.lastName}`}</span>
        </div>
        <div>
          <span className="icon icon-envelop" />
          <span>{`${userDetails.emailAddress}`}</span>
        </div>
        {
          isAccountManager(userDetails) && regions.length > 0 ?
          <div>
            <span className="icon icon-folder" />
            <span>{ userDetails.regionId !== -1
              ? `${getNormalizedEnumString(regions.find(r => r.id === userDetails.regionId).name)}`
              : 'No Region' }</span>
          </div>
          : null
        }
      </div>
      {
        isAdmin(userDetails) ?
        <div className="administrators">
          <h4>Staff</h4>
          <p>View and manage staff accounts</p>
          <Button
            type="button"
            className="link"
            text="Manage Staff"
            onClick={() => navigateTo('/admin/admin-users')}
          />
        </div>
        : null
      }
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    regions: state.regions.regions,
  };
};

export default connect(mapStateToProps)(PersonalDetailsCard);
