import { AxiosPromise } from 'axios';
import Fetch from '../apiConfig/apiConfig';
import queryString from 'query-string';
import { IOrderDispatchResponse } from '../LogisticsOrderDispatch/IOrderDispatchResponse';
import { IOrderDispatch } from '../../types';
import { IImportLogisticsConfirmationFormParams } from '../LogisticsOrderDispatch/IImportLogisticsConfirmationFormParams';
import { IImportLogisticsConfirmationFormResponse }
  from '../LogisticsOrderDispatch/IImportLogisticsConfirmationFormResponse';
import { IExportLogisticsConfirmationFormResponse }
  from '../LogisticsOrderDispatch/IExportLogisticsConfirmationFormResponse';

export interface ISubmitOrderDispatchParams {
  order: IOrderDispatch;
}

export interface IImportOrderDispatchParams {
  order: IImportLogisticsConfirmationFormParams;
}

export interface IExportLogisticsConfirmationFormParams {
  [key: string]: any;
  orderNumber: string;
}

export const getOrderDispatchApi = (): AxiosPromise<IOrderDispatchResponse> => {
  return Fetch().then(axios => axios.get('orders/logisticsconfirmation'));
};

export const submitOrderDispatchApi = (params: ISubmitOrderDispatchParams): AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/logisticsconfirmation/submit', params));
};

export const saveOrderDispatchApi = (params: ISubmitOrderDispatchParams): AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/logisticsconfirmation/edit', params));
};

export const exportOrderDispatchApi =
  (params: IExportLogisticsConfirmationFormParams): AxiosPromise<IExportLogisticsConfirmationFormResponse> => {
    return Fetch().then(axios => axios.get(`orders/logisticsconfirmationform/export?${queryString.stringify(params)}`));
  };

export const importOrderDispatchApi =
  (params: IImportLogisticsConfirmationFormParams): AxiosPromise<IImportLogisticsConfirmationFormResponse> => {
    return Fetch().then(axios => axios.post('orders/logisticsconfirmationform/import', params));
  };
