import { Dispatch } from 'redux';
import { getRegions } from '../../api/regionsApi';

export const GET_REGIONS_REQUEST = 'GET_REGIONS_REQUEST';
export const GET_REGIONS_RESPONSE = 'GET_REGIONS_RESPONSE';
export const GET_REGIONS_ERROR = 'GET_REGIONS_ERROR';

export const getRegionsRequest = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GET_REGIONS_REQUEST,
    });

    try {
      const response = await getRegions();
      if (response.status === 200) {
        dispatch({
          type: GET_REGIONS_RESPONSE,
          response: {
            regions: response.data.regions,
          },
        });
      } else {
        getRegionsError(dispatch);
      }
    } catch (error) {
      getRegionsError(dispatch);
    }
  };
};

const getRegionsError = (dispatch: any) => dispatch({
  type: GET_REGIONS_ERROR,
});
