import { Reducer } from 'redux';
import { IRegionsState } from '../../types';
import * as RegionActions from '../../actions/SupplyChain/regionsAction';

const initialState: IRegionsState = {
  error: '',
  loading: false,
  regions: [],
};

const regionsReducer: Reducer<IRegionsState> =
  (state: IRegionsState = initialState, action: any) => {
    switch (action.type) {
      case RegionActions.GET_REGIONS_REQUEST:
        {
          return {
            ...state,
            error: '',
            loading: true,
          };
        }
      case RegionActions.GET_REGIONS_RESPONSE: {
        return {
          ...state,
          error: '',
          loading: false,
          regions: action.response.regions,
        };
      }
      case RegionActions.GET_REGIONS_ERROR: {
        return {
          ...state,
          error: action.response || 'An error has occured. Please try again',
          loading: false,
        };
      }
      default: {
        return state;
      }
    }
  };

export default regionsReducer;
